<template>
  <div>
    <my-nav-bar
      title="盘点记录"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        v-model="loading"
        class="list"
        :finished="finished"
        @load="getList"
      >
        <div v-for="(stocktaking, index) in list" :key="index" class="item">
          <van-cell :title="stocktaking.user.name" :value="stocktaking.created_at" />
          <van-cell
            v-if="stocktaking.use_minutes || stocktaking.use_seconds"
            title="用时"
            :value="getTimes(stocktaking)"
          />
          <van-cell :title="'盘点前库存：' + stocktaking.before_stock" :value="'盘点后库存：' + stocktaking.after_stock" :label="'盘点日志：' + stocktaking.content" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getStockTaking } from '@/api/warehouse_goods'
export default {
  name: 'StockTakingRecord',
  components: { myNavBar },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      listQuery: {
        goods_id: this.$route.query.goods_id,
        goods_warehouse_id: this.$route.query.goods_warehouse_id,
        limit: 10,
        page: 0
      },
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getStockTaking(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    getTimes(stocktaking) {
      let times = ''
      if (stocktaking.use_minutes > 0) {
        times += stocktaking.use_minutes + '分'
      }
      if (stocktaking.use_seconds > 0) {
        times += stocktaking.use_seconds + '秒'
      }
      return times
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 15px 15px 5px 15px;
  }
  .item {
    padding-bottom: 10px;
  }
</style>
