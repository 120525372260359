import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'warehouse/goods',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'warehouse/goods/detail',
      params
    })
  },
  modify(data) {
    return request({
      method: 'post',
      url: 'warehouse/goods/modify',
      data
    })
  },
  lock(data) {
    return request({
      method: 'post',
      url: 'warehouse/goods/lock',
      data
    })
  },
  unlock(data) {
    return request({
      method: 'post',
      url: 'warehouse/goods/unlock',
      data
    })
  },
  stocktaking(data) {
    return request({
      method: 'post',
      url: 'warehouse/goods/stocktaking',
      data
    })
  }

}

// 添加储位
export function addStorage(data) {
  return request({
    method: 'post',
    url: 'warehouse/goods/storage',
    data
  })
}

// 删除商品仓库
export function delGoodsWarehouse(data) {
  return request({
    method: 'delete',
    url: `warehouse/goods/${data.id}`
  })
}

// 商品类别
export function getCategories(params) {
  return request({
    method: 'get',
    url: 'warehouse/goods/category',
    params
  })
}

// 盘点记录
export function getStockTaking(params) {
  return request({
    method: 'get',
    url: 'warehouse/goods/stocktaking',
    params
  })
}

// 库存记录
export function getStockLogs(params) {
  return request({
    method: 'get',
    url: `warehouse/goods/stock-logs`,
    params
  })
}
