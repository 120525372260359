var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "盘点记录",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (stocktaking, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('van-cell', {
      attrs: {
        "title": stocktaking.user.name,
        "value": stocktaking.created_at
      }
    }), stocktaking.use_minutes || stocktaking.use_seconds ? _c('van-cell', {
      attrs: {
        "title": "用时",
        "value": _vm.getTimes(stocktaking)
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": '盘点前库存：' + stocktaking.before_stock,
        "value": '盘点后库存：' + stocktaking.after_stock,
        "label": '盘点日志：' + stocktaking.content
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }